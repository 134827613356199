import { ItemFeature } from '@sbt-web/networking';

enum PackLabelURIMap {
  Marca = 'brand',
  Modello = 'model',
  Versione = 'version',
}

export function isPack(f: ItemFeature): boolean {
  return f.type === 'pack';
}

export function mapPackToItemFeatures(features: {
  [key: string]: ItemFeature;
}): { [key: string]: ItemFeature } {
  const retFeatures = { ...features };
  Object.keys(retFeatures).forEach((key) => {
    const value = retFeatures[key];
    if (isPack(value)) {
      value.values.forEach(
        (f) =>
          (retFeatures[
            `${value.uri}/${PackLabelURIMap[(f['label'] as keyof typeof PackLabelURIMap) || '']}`
          ] = {
            uri: `${value.uri}/${PackLabelURIMap[(f['label'] as keyof typeof PackLabelURIMap) || '']}`,
            type: 'list',
            label: f['label'] || '',
            values: [{ key: f.key, value: f.value }],
          })
      );
    }
  });
  return retFeatures;
}

export function mapPackFeatures(
  features: ItemFeature[]
): { label: string | undefined; value: string }[] {
  return features
    .filter(isPack)
    .map((f) =>
      f.values.map((pf) => ({
        label: pf.label,
        value: pf.value,
      }))
    )
    .reduce((acc, f) => acc.concat(f), []);
}
