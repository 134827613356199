import type { AdUnitSize } from '@client/components/Adv/GPT/polarisSkyscrapers/usePolarisSkyscrapers';
import {
  reloadAdvWithDefaultUnits,
  runAdv,
} from '@client/components/Adv/GPT/sdkIntegration';
import type { CategoryId } from '@sbt-web/networking';
import type { ENV } from '@sbt-web/utils';
import {
  OptimizelySubitoContext,
  type ReactSDKClient,
} from '@sbt-web/houston-wrapper';
import type { DecoratedItem } from '@shared/models';
import React, { useContext, useEffect } from 'react';
import type { ListingPageProps } from '../../../../pages/listing';
import { AdvContext } from '@client/components/Adv/AdvContext';

export let loadedForTheFirstTime = false;
let scriptIsDecidedGlobal = false;
let prebidDecidePromise: Promise<void> | undefined;

/**
 * Implemented only to test the hooks
 */
export const resetLoadedForTheFirstTime = function (val: boolean = false) {
  loadedForTheFirstTime = val;
};
export const resetScriptIsDecidedGlobal = function (val: boolean = false) {
  scriptIsDecidedGlobal = val;
};
export const resetPrebidDecidePromise = () => {
  prebidDecidePromise = undefined;
};

export const forceToUsePrebid = function () {
  const FORCE_PREBID = 'forcePrebid';
  return window.sessionStorage.getItem(FORCE_PREBID) === '1';
};

const decideAndLoadPrebid = (optimizely: ReactSDKClient) => {
  if (!prebidDecidePromise) {
    prebidDecidePromise = new Promise((resolver) => {
      optimizely.onReady().then(() => {
        //Activate the experiment to decide if we should use prebid or not.
        // There are two ways, through the optimizely or through the sessionStorage
        const usePrebid =
          forceToUsePrebid() ||
          optimizely.activate('subito-adv-listing-prebid') === 'B';

        window.subitoAdvQueue.push(() => {
          //Load the script only once:
          if (!scriptIsDecidedGlobal) {
            scriptIsDecidedGlobal = true;
            window.subitoAdv.gpt.loadPrebidOrOwDinamically(usePrebid);
          }
          resolver();
        });
      });
    });
  }
  return prebidDecidePromise;
};

export const useScriptLoaded = function () {
  const [scriptLoaded, setScriptLoaded] = React.useState(scriptIsDecidedGlobal);
  const { optimizely } = useContext(OptimizelySubitoContext);
  useEffect(() => {
    if (!scriptLoaded && optimizely) {
      decideAndLoadPrebid(optimizely).then(() => {
        setScriptLoaded(scriptIsDecidedGlobal);
      });
    }
  }, [optimizely, scriptLoaded]);

  return scriptLoaded;
};

export const useAdvListing = function (
  props: ListingPageProps,
  clientReady: boolean,
  isInterstitialDesktopEnabled: boolean,
  skyscraperSizes?: Array<AdUnitSize>
) {
  const [dataLayerReady, setDataLayerReady] = React.useState(false);
  const scriptLoaded = useScriptLoaded();

  React.useEffect(() => {
    if (
      dataLayerReady &&
      !loadedForTheFirstTime &&
      clientReady === true &&
      skyscraperSizes &&
      scriptLoaded
    ) {
      loadedForTheFirstTime = true;
      //Set ADV:
      runAdv(
        'listing',
        props.category?.id as CategoryId,
        process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV,
        undefined,
        undefined,
        isInterstitialDesktopEnabled,
        skyscraperSizes
      );
    }
  }, [
    dataLayerReady,
    clientReady,
    isInterstitialDesktopEnabled,
    skyscraperSizes,
    scriptLoaded,
  ]);

  return {
    setDataLayerReadyForAdv: setDataLayerReady,
  };
};

export const useUpdateAdvWhenItemsChanged = function (
  listingCategoryId: CategoryId,
  items: Array<DecoratedItem>,
  isDesktop: boolean,
  isModalOpened: boolean
) {
  const { skyscraperData } = useContext(AdvContext);

  React.useEffect(() => {
    if (loadedForTheFirstTime && !isModalOpened) {
      reloadAdvWithDefaultUnits(
        isDesktop,
        'listing',
        listingCategoryId,
        process.env.NEXT_PUBLIC_INTERNAL_ENVIRONMENT as ENV,
        undefined,
        undefined,
        undefined,
        skyscraperData.sizes
      );
    }
  }, [items, isModalOpened, skyscraperData.sizes]);
};
